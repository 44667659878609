@use '../../resources/my-medicoach/styles/scss.module' as *;

.error {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  flex-direction: column;
  height: 100%;

  h2 {
    color: inherit;
    font-size: $fontSizeMainTitle;
    font-weight: 500;
  }

  h2 {
    margin: 0;
  }

  p {
    margin: 1em 0;
    background: white;
  }
}

.errorContents {
  background: white;
  padding: 24px;

  width: 100%;
  max-width: 348px;

  @media (max-width: 500px) {
    padding: 16px;
  }
}

.errorLogo.errorLogo {
  max-width: 300px;
  margin: 24px;

  @media (max-width: 500px) {
    margin: 16px;
  }
}
