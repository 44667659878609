@use '../../resources/my-medicoach/styles/scss.module.scss' as *;

@import '~normalize.css';
@import url('https://fonts.googleapis.com/css?family=Rubik:400,500|Poppins:400,600');

*,
*::before,
*::after {
  box-sizing: inherit;
}

html {
  box-sizing: border-box;

  font-family: $contentFont;
  color: #000000;
  min-height: 100%;
  font-weight: 400;
  line-height: $defaultLineHeight;
}

html,
body {
  // prevent pull-to-refresh on chrome
  overscroll-behavior: contain;
}

html,
body {
  display: flex;
  flex-direction: column;
  flex: 1;
}

:global(#app) {
  display: contents;
}

:root {
  --page-background: white;
}

body {
  background-color: var(--page-background, white);
  scrollbar-color: var(--category-color-dark, #{$primaryDark}) #dbdbdb;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $titleFont;
  font-weight: 400;
  margin: 0;
}

h1 {
  font-size: $fontSizeMainTitle;
}

h2 {
  font-size: $fontSizeSubtitle;
}

h3 {
  font-size: $fontSizeDefault;
}

p {
  margin: 0 0 1em;
}

strong {
  font-weight: 600;
}

a {
  //color: #4ba2ca;
  text-decoration: none;
}

form {
  margin: 0;
}

form span {
  width: auto;
}

select {
  border-radius: 5px;
  cursor: pointer;
}

:-moz-ui-invalid {
  box-shadow: none;
}

body:global(.js-focus-visible) *:not(:global(.focus-visible)) {
  outline: none;
}

body:not(:global(.js-focus-visible)) {
  &:not(:focus-visible) {
    outline: none;
  }
}

@keyframes :global(animFadeOut) {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    visibility: hidden;
  }
}

@keyframes :global(fadeIn) {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes :global(slideInUp) {
  from {
    transform: translateY(20%);
    opacity: 0;
  }

  to {
    transform: none;
    opacity: 1;
  }
}

:global(.visually-hidden) {
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}
