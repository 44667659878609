@use '../../../resources/my-medicoach/styles/scss.module' as *;

$horizontal-padding: $pageTextStart;

.listItem {
  background: white;
  color: $brand-black;

  //box-shadow: $materialShadow1;
  border-radius: 4px;
  display: flex;
  position: relative;

  align-items: center;
  border: 1px solid rgb(0 0 0 / 0.24);

  &.hasActions {
    --right-safe-area: #{48px + 8px};
  }
}

.invertedScheme {
  background: var(--category-color-light, #{$catPhysiotherapyLight});
  color: $brand-black;
}

.interactiveWrapper.interactiveWrapper.interactiveWrapper {
  display: flex;
  align-items: center;
  padding: 16px calc(var(--right-safe-area, 8px) + 8px) 16px 16px;
  color: inherit;
  flex: 1;
  line-height: 1.65;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;

  transition: background-color 230ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

  .active & {
    background: rgb(0 0 0 / 0.075);
  }

  &::before {
    content: '';
    display: block;
    width: 3px;
    height: 100%;
    position: absolute;
    right: 0;
    top: 0;
    background: var(--category-color-dark);
    opacity: 0;
    transition: opacity 230ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  }

  .active &::before {
    opacity: 1;
  }
}

.icon {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-shrink: 0;
  // ensure .iconText isn't larger than .icon
  width: min-content;
}

.iconLeft {
  margin-right: 16px;
}

.iconText {
  font-size: $fontSizeTiny;
  font-weight: 600;
  text-align: center;
  margin-top: 8px;
  margin-bottom: 0;
  line-height: 1.2;
}

.text {
  flex: 1;

  display: flex;
  flex-direction: column;
}

.title {
  display: block;
  font-family: $titleFont;
  //font-weight: 500;
  font-size: $fontSizeDefault;
  line-height: 1;
  margin-bottom: 0;
}

.subtitle {
  font-size: $fontSizeDetail;
  display: block;
  margin-top: 2px;
}

.touchesScreenBorder .wrapper {
  padding-left: calc(env(safe-area-inset-left, 0px) + #{$horizontal-padding});
  padding-right: calc(env(safe-area-inset-right, 0px) + #{$horizontal-padding});
}

.actionSection {
  position: absolute;
  right: 8px;
}

.moreBtn.moreBtn {
  color: currentColor;
}
