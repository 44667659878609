@use '../../resources/my-medicoach/styles/scss.module' as *;

$backdropOpacity: 0.9;

.physiotherapy {
  --category-color-light: #{$catPhysiotherapyLight};
  --category-color-dark: #{$catPhysiotherapyDark};

  // used by BlueModal's backdrop
  --category-color-backdrop: #{rgba($catPhysiotherapyDark, $backdropOpacity)};
}

.dietetics {
  --category-color-light: #{$catDieteticsLight};
  --category-color-dark: #{$catDieteticsDark};
  --category-color-backdrop: #{rgba($catDieteticsDark, $backdropOpacity)};
}

.occupationalTherapy {
  --category-color-light: #{$catOccupationalTherapyLight};
  --category-color-dark: #{$catOccupationalTherapyDark};
  --category-color-backdrop: #{rgba($catOccupationalTherapyDark, $backdropOpacity)};
}

.speechTherapy {
  --category-color-light: #{$catSpeechTherapyLight};
  --category-color-dark: #{$catSpeechTherapyDark};
  --category-color-backdrop: #{rgba($catSpeechTherapyDark, $backdropOpacity)};
}

.medicine {
  --category-color-light: #{$catMedicineLight};
  --category-color-dark: #{$catMedicineDark};
  --category-color-backdrop: #{rgba($catMedicineDark, $backdropOpacity)};
}

.psychology {
  --category-color-light: #{$catPsychologyLight};
  --category-color-dark: #{$catPsychologyDark};
  --category-color-backdrop: #{rgba($catPsychologyDark, $backdropOpacity)};
}

.tobaccology {
  --category-color-light: #{$catTobaccologyLight};
  --category-color-dark: #{$catTobaccologyDark};
  --category-color-backdrop: #{rgba($catTobaccologyDark, $backdropOpacity)};
}

.sportCoaching {
  --category-color-light: #{$catSportLight};
  --category-color-dark: #{$catSportDark};
  --category-color-backdrop: #{rgba($catSportDark, $backdropOpacity)};
}

.other {
  --category-color-light: #{$catOtherLight};
  --category-color-dark: #{$catOtherDark};
  --category-color-backdrop: #{rgba($catOtherDark, $backdropOpacity)};
}
