@use '../../resources/my-medicoach/styles/scss.module' as *;
@use '../../resources/my-medicoach/styles/zIndices.module' as *;

.navItem.navItem {
  color: rgb(0 0 0 / 0.54);
  line-height: 1.65;

  &.active {
    color: var(--header-background, #{$primaryDark});
  }

  .icon svg {
    font-size: 1.96rem;
  }
}

// side nav
.drawer {
  display: flex;
  flex-direction: column;
  // handled by <MultiPageView>
  //border-right: 1px rgb(0 0 0 / 0.12) solid;
  z-index: $z-index-dock;

  .navItem {
    border-radius: 16px;
    width: calc(100% - 16px);
    margin-right: 8px;
    margin-left: 8px;
  }

  hr {
    margin: 0 8px;
  }

  :global(.MuiList-root) > *:not(:first-child) {
    margin-top: 8px;
  }
}

.drawerToolbar {
  height: 64px;
  display: flex;
  padding: 8px 14px;

  img {
    height: 48px;
    width: 48px;
  }
}

.dashboardSwitchList.dashboardSwitchList {
  margin-top: auto;
}

@media (max-width: 1000px) {
  .drawer {
    width: 64px;
    overflow: hidden;

    .navItem {
      border-radius: 0;
      margin: 0;
      width: 100%;
    }
  }

  .drawerToolbar {
    padding: 8px;
  }
}

.drawer .active {
  // !important to overwrite material UI
  /* stylelint-disable-next-line */
  background: $activeTransparencyOverlay !important;
}

.icon.icon {
  color: inherit;
}

// bottom nav
.bottomNavWrapper {
  // this is a hack:
  // bottom nav labels are overflowing towards the bottom when in the "not-selected" state
  // this hides them but has a little bit of extra space at the top to keep the Notification Badge visible
  overflow: hidden;
  padding-top: 4px;
  margin-top: -4px;
}

.bottomNav.bottomNav {
  z-index: $z-index-dock;
  border-top: 1px solid #e0e0e0;

  height: calc(56px + env(safe-area-inset-bottom));
  padding-bottom: env(safe-area-inset-bottom);
}

.bottomNavItem.bottomNavItem {
  min-width: auto;
  transition: color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, padding 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  height: 100%;

  @media (max-width: 350px) {
    padding-left: 0;
    padding-right: 0;
  }
}

.bottomNavItemInactive.bottomNavItemInactive {
  padding-bottom: 0;
}

.bottomNavItemSelected.bottomNavItemSelected.bottomNavItemSelected {
  font-size: 0.75rem;
}

.bottomNavItemLabel.bottomNavItemLabel {
  transition: opacity 0.2s;
  transition-delay: 0.1s;
}

.navIcon {
  transition: transform 0.3s, color 0.3s;
  display: flex;
}

:global(#dock__therapistChat),
:global(#dock__patientChat) {
  .badge {
    background: $unreadNotificationColor;
    color: white;
  }
}

:global(#dock__settings) {
  .badge {
    background: $updateButtonColor;
  }

  &.active .navIcon {
    transform: rotate(225deg);
  }
}
