@use '../../../resources/my-medicoach/styles/scss.module' as *;
@use '../../../resources/my-medicoach/styles/zIndices.module' as *;

.backdrop.backdrop {
  height: 100vh;

  background: var(--category-color-backdrop);

  // SCSS doesn't support rgba(val / 0.9)
  /* stylelint-disable-next-line */
  //background: rgba($primaryDark, 0.9);
  display: flex;
  justify-content: center;
  //align-items: center; // unsafe :( "center safe" is not yet available -- use margin auto instead
  // extra padding to prevent close button from hiding content
  padding: 32px;
  overflow: auto;
  z-index: $z-index-dialogs;

  :global {
    animation: fadeIn 0.3s;
  }

  &:not(.notClosable) {
    cursor: pointer;
  }

  @media (max-width: 450px) {
    padding: 16px;
  }

  @media (max-width: 380px) {
    padding: 8px;
  }
}

.header {
  display: grid;

  min-height: 64px;
  --back-width: 48px;
  --actions-width: 48px;
  grid-template-columns: var(--back-width) 1fr var(--actions-width);
  padding: 8px;
  align-items: flex-start;

  @media (max-width: 400px) {
    &:not(.hasActions) {
      --actions-width: 0px;
    }

    &:not(.hasBack) {
      --back-width: 0px;
    }
  }
}

.actionsMenu {
  grid-area: 1 / 3;
}

.modal {
  box-shadow: 0 0 2px 0 rgb(0 0 0 / 0.12), 0 2px 2px 0 rgb(0 0 0 / 0.24);
  border-radius: 4px;

  margin: auto;
  width: 100%;
  max-width: 500px;

  cursor: default;

  &.contained {
    max-height: 100%;
    min-height: 330px;
    display: flex;
    flex-direction: column;
    overflow: hidden;
  }
}

.body {
  padding: 16px 24px;
  flex: 1;

  .contained & {
    display: flex;
    flex-direction: column;
    overflow: hidden;
  }

  & > :global(.full-bleed) {
    margin-left: -16px;
    margin-right: -16px;
    width: calc(100% + 32px);
  }
}

.backdrop:not([open]) {
  display: none;
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

.title {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  align-self: center;
  font-family: $titleFont;
  line-height: 1.2;

  grid-column: 2;
}

.blueModalRestrictedContent {
  width: 100%;
  max-height: 300px;
  overflow: auto;
}
