@use '../../../resources/my-medicoach/styles/scss.module' as *;
@use '../../../resources/my-medicoach/styles/zIndices.module' as *;

.dialog {
  display: block;
  border: 0;
  position: fixed;
  z-index: $z-index-drawers;
  width: 100%;
}
