@use '../../resources/my-medicoach/styles/scss.module' as *;
@use '../../resources/my-medicoach/styles/zIndices.module' as *;

:global {
  .section--color-light {
    --header-background: var(--category-color-light);
    --header-text: #{$brand-black};
  }

  .section--white,
  .section--color-dark {
    --header-background: var(--category-color-dark);
    --header-text: #{$brand-white};
  }
}

html {
  --top-bar-height: #{$topBarHeightMobile};

  @media (min-width: 700px) {
    --top-bar-height: #{$topBarHeightDesktop};
  }
}

.header {
  flex-shrink: 0;
  display: flex;

  height: var(--top-bar-height, 0px);
  // iOS notch decent display
  height: calc(var(--top-bar-height, 0px) + env(safe-area-inset-top, 0px));
  padding-top: env(safe-area-inset-top, 0px);
  padding-left: env(safe-area-inset-right, 0px);
  padding-right: env(safe-area-inset-left, 0px);

  text-align: center;
  position: relative;
  left: 0;
  right: 0;
  top: 0;
  z-index: $z-index-dock;

  background: var(--header-background);
  color: var(--header-text);
}

.headerContent.headerContent {
  flex: 1;
  display: grid;
  --back-button-width: 48px;
  $width-per-action: 48px;
  --internal-action-count: var(--action-count);
  grid-template-columns: var(--back-button-width) 1fr calc(#{$width-per-action} * var(--internal-action-count));
  padding-left: 4px;
  padding-right: 4px;
  justify-content: space-between;
  align-items: center;

  .hasBackButton.ios & {
    --internal-action-count: max(1, var(--action-count));
  }

  .header:not(.hasTitle) &,
  .ios & {
    --back-button-width: calc(#{$width-per-action} * var(--internal-action-count));
  }

  .hasTitle:not(.hasBackButton):not(.ios) & {
    --back-button-width: 0px;
    padding-left: 16px;
  }
}

.logo,
.titleWrapper {
  grid-column: 2;
}

.logo {
  // height: 100% doesn't work in chrome
  height: var(--top-bar-height);
  max-width: 100%;
  object-fit: contain;

  padding: 16px 0 5px;
  box-sizing: border-box;
  margin: 0 auto;
}

.titleWrapper {
  display: flex;
  height: 100%;
  align-items: center;
  overflow: hidden;
  flex: 1;

  &.ios {
    justify-content: center;
  }
}

.title {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;

  font-weight: 400;
  //padding: 20px 0;
  margin: 0;
  text-align: left;

  font-size: 1.3rem;
  line-height: 1;

  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  font-family: $titleFont;

  .ios & {
    text-align: center;
  }

  .overlayScrollbar & {
    overflow: auto;
  }

  a {
    text-decoration: none;
    color: inherit;
  }
}

.subtitle {
  font-family: $contentFont;
  font-size: $fontSizeDefault;
  opacity: 0.8;
}

.title,
.logo {
  transition: opacity 0.15s ease-in;

  .backButtonHovered & {
    opacity: 0;
  }
}

.headerActions {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.leftButton.leftButton,
.rightButton.rightButton {
  flex-shrink: 0;
  color: inherit;
  height: 48px;
  width: 48px;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.leftButton.leftButton {
  left: 0;
  position: relative;

  .backButtonHovered & {
    &::after {
      content: attr(title);
      position: absolute;
      left: 100%;
      width: max-content;
      animation: slideInLeft 0.3s ease-out forwards;
      top: 50%;
      font-size: $fontSizeMainTitle;
    }
  }
}

@keyframes slideInLeft {
  from {
    transform: translateX(10%) translateY(-50%);
    opacity: 0;
  }

  to {
    transform: translateY(-50%);
    opacity: 1;
  }
}
