@use '../../../resources/my-medicoach/styles/scss.module' as *;
@use 'sass:math';

$offset: 187;
$duration: 1.4s;

.spinner {
  animation: rotator $duration linear infinite;
  animation-fill-mode: forwards;
}

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;

  stroke: var(--section-affordance);
}

.inheritColor {
  stroke: currentColor;
}

@keyframes rotator {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(270deg);
  }
}

.path {
  stroke-dasharray: $offset;
  stroke-dashoffset: 0;
  transform-origin: center;
  animation: dash $duration ease-in-out infinite;
  animation-fill-mode: forwards;
}

@keyframes dash {
  0% {
    stroke-dashoffset: $offset;
  }

  50% {
    stroke-dashoffset: math.div($offset, 4);
    transform: rotate(135deg);
  }

  100% {
    stroke-dashoffset: $offset;
    transform: rotate(450deg);
  }
}
