@use '../../../resources/my-medicoach/styles/scss.module' as *;

.spinner {
  margin: 40px auto;
  width: 40px;
  height: 40px;
  position: relative;
  text-align: center;

  animation: sk-rotate 2s infinite linear;

  color: var(--section-affordance);
}

.dot1,
.dot2 {
  width: 60%;
  height: 60%;
  display: inline-block;
  position: absolute;
  top: 0;
  background-color: currentColor;
  border-radius: 100%;

  animation: sk-bounce 2s infinite ease-in-out;
}

.dot2 {
  top: auto;
  bottom: 0;
  animation-delay: -1s;
}

@keyframes sk-rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes sk-bounce {
  0%,
  100% {
    transform: scale(0);
  }

  50% {
    transform: scale(1);
  }
}
