@use '../../../resources/my-medicoach/styles/scss.module' as *;

.container {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  max-width: 1265px;
  width: 100%;
  margin: 0 auto;
}

.container.vertical {
  flex-direction: row-reverse;
}

.childrenWithDock {
  overflow: hidden;
  flex: 1 1;
  height: 100%;
  display: flex;
  flex-direction: column;
  width: 100%;
}
