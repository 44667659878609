@use '../../../resources/my-medicoach/styles/scss.module' as *;

$pageButtonHeight: 50px;
$sponsorsHeight: 40px;
$dockHeight: 40px;

.appPage {
  flex: 1;
  height: 100%;
  display: flex;
  flex-direction: column;

  width: 100%;

  overflow: auto;

  .fullWidth & {
    overflow: hidden;
  }
}

.header {
  transition-duration: 0.13s;
  transition-timing-function: ease-out;
  transition-property: box-shadow;

  .notFullWidth & {
    position: sticky;
    top: 0;
  }

  &.scrolled {
    box-shadow: $materialShadow1;
  }
}

.main {
  .fullWidth & {
    overflow: auto;
  }
}

.main {
  flex: 1;
  width: 100%;

  display: flex;
  flex-direction: column;
}

.contentContainer.contentContainer {
  flex: 1;
  position: relative;
  display: flex;
  flex-direction: column;
  padding-left: 16px;
  padding-right: 16px;

  & > :global(.full-bleed) {
    margin-left: -16px;
    margin-right: -16px;
    width: calc(100% + 32px);
  }

  // i'd like to use grid for this but major issue:
  // i need the last row to take up the remaining height
  // so things like floating actions can be at the bottom of the page.
  // That's not really possible so instead I use flex & negative margins.

  //grid-template-columns: 16px 1fr 16px;
  //grid-template-rows: auto 1fr;
  //align-content: start;
  //
  //> :not(:global(.full-bleed)) {
  //  grid-column: 2;
  //}
  //
  //> :global(.full-bleed) {
  //  width: 100%;
  //  grid-column: 1 / 4;
  //}
}

.notchSpacer {
  height: env(safe-area-inset-bottom, 0px);
  flex-shrink: 0;
}

//.appPage:not(.hasDock) .sponsor {
//  padding-bottom: env(safe-area-inset-bottom, 0px);
//}

@media (max-height: 501px) {
  .sponsors {
    display: none;
  }
}
