.container {
  flex: 1;
  width: 100%;

  @media (min-width: 700px) {
    max-width: 600px;
    margin: 0 auto;

    border-left: 1px rgb(0 0 0 / 0.12) solid;
    border-right: 1px rgb(0 0 0 / 0.12) solid;
  }
}
