@use '../../resources/my-medicoach/styles/scss.module' as *;

.card {
  padding: 16px;
  border-radius: 4px;
  box-shadow: $materialShadow1;

  > :first-child {
    margin-top: 0;
  }

  > :last-child {
    margin-bottom: 0;
  }

  p {
    margin-bottom: 0;
  }

  strong {
    color: hsl(0 0% 11%);
  }
}
