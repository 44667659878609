@import '../../resources/my-medicoach/styles/scss.module';

:global {
  .section--color-light {
    --page-background: var(--category-color-light);
    background: var(--category-color-light);
    color: $brand-black;

    --section-affordance: #{$brand-black};
    --section-block-background: #{$brand-white};
    --section-block-color: #{$brand-black};

    --section-error-affordance: #{$errorDark};
    --section-error-block-background: #{$errorLight};
  }

  .section--color-dark {
    --page-background: var(--category-color-dark);
    background: var(--category-color-dark);
    color: $brand-white;

    --section-affordance: #{$brand-white};
    --section-block-background: #{$brand-black};
    --section-block-color: #{$brand-white};

    --section-error-affordance: #{$errorLight};
    --section-error-block-background: #{$errorLight};
  }

  .section--white {
    --page-background: #{$brand-white};
    background: $brand-white;
    color: $brand-black;

    --section-affordance: var(--category-color-dark);
    --section-block-background: var(--category-color-light);
    --section-block-color: #{$brand-black};

    --section-error-affordance: #{$errorDark};
    --section-error-block-background: #{$errorLight};
  }
}
