@import '../../resources/my-medicoach/styles/scss.module';

$overlayOpacity: 0.15;

.button {
  position: relative;
  flex-shrink: 0;
  min-height: 48px;
  min-width: 48px;

  cursor: pointer;

  &.loading {
    cursor: wait;
  }

  &.disabled {
    cursor: not-allowed;
  }

  &.disabled .visualButton {
    filter: grayscale(100%);
    opacity: 0.5;
  }

  &:not(.disabled, .loading):hover .visualButton::after {
    content: '';
    display: block;
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    background: currentColor;
    opacity: $overlayOpacity;
    pointer-events: none;
    animation: fadeIn 0.3s;
  }
}

.visualButton {
  width: 100%;
  appearance: none;
  position: relative;
  overflow: hidden;
  text-align: center;
  line-height: 1.15;
  transition: background-color 0.25s cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    color 0.25s cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    border 0.25s cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    box-shadow 0.25s cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    filter 0.25s cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    border-radius 0.25s cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: $overlayOpacity;
  }
}

// ========== COLOR-SCHEME ===========

.default.default {
  --button-main: var(--section-block-background);
  --button-outlined: var(--section-affordance);
  --button-contrast: var(--section-block-color);
}

.danger.danger {
  --button-main: var(--section-error-block-background);
  --button-outlined: var(--section-error-affordance);
  --button-contrast: var(--section-block-color);
}

// ========== VARIANTS ===========

.plain.plain {
  color: var(--button-contrast);

  .visualButton {
    border: 1px var(--button-main) solid;
    background: var(--button-main);
  }
}

.outlined.outlined {
  color: var(--button-outlined);

  .visualButton {
    border: 1px var(--button-outlined) solid;
  }
}

.borderless.borderless {
  color: var(--button-outlined);

  .visualButton {
    border: 1px transparent solid;
  }
}

// ========== SIZES ===========

$iconSize: 1.25;

.medium .visualButton,
.large .visualButton {
  border-radius: 4px;
}

.small .visualButton {
  border-radius: 16px;
  height: 32px;
  padding: 8px 12px;
  font-size: 0.88875rem;

  --icon-size: #{0.88875rem * $iconSize};
  --horizontal-padding: 12px;
  --size: 32px;
}

.medium .visualButton {
  padding: 0.8em 16px;
  font-size: 1rem;

  --icon-size: #{1rem * $iconSize};
  --horizontal-padding: 16px;
  --size: 46px;
}

.large .visualButton {
  font-size: 1.125rem;
  padding: 18px 21px;

  --icon-size: #{1.125rem * $iconSize};
  --horizontal-padding: 21px;
  --size: 58.7px;
}

// ========== OTHER ===========

.floating .visualButton {
  box-shadow: $materialShadow6;
  border-radius: 16px;
}

$borderPadding: 16px;

.hasIcon:not(.noChildren) .visualButton {
  position: relative;
  padding-right: calc(var(--horizontal-padding) * 1.3 + var(--icon-size));
}

.hasIcon.noChildren .visualButton {
  border-radius: 50%;
  width: var(--size);
  height: var(--size);
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.icon.icon {
  font-size: var(--icon-size);
}

.hasIcon:not(.noChildren) .icon {
  position: absolute;
  right: var(--horizontal-padding);
  top: 50%;
  margin-top: calc(-1 * var(--icon-size, 0rem) / 2);
}

.loadingIndicator {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.popper {
  position: absolute;
  bottom: calc(100% + 8px);

  // if text is smaller than button: take width of text
  // otherwise take width of button, with a minimum of 200px (for small buttons)
  max-width: max(100%, 200px);
  width: max-content;

  background: white;
  padding: 5px 10px;
  border-radius: 4px;
  text-align: left;
  pointer-events: none;
  box-shadow: $materialShadow1;

  display: none;

  @media (any-hover: hover) {
    .button:hover & {
      display: block;
    }
  }

  p {
    margin: 0;
  }
}
