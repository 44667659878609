.list {
  composes: flatList from '../../../resources/my-medicoach/styles/composables/flat-list.module.scss';

  > * {
    margin: 8px;
  }

  > :last-child {
    margin-bottom: 0;
  }
}
