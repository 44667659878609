@use '../../resources/my-medicoach/styles/scss.module' as *;

.display {
  margin: auto;
  padding: 16px;
  border-radius: 16px;
}

.title {
  color: inherit;
  text-transform: capitalize;

  &::after {
    content: '';
    display: block;
    width: 80%;
    height: 8px;
    background: currentColor;
    margin-top: 10px;
  }
}

.link {
  display: block;
  color: inherit;

  &:hover {
    text-decoration: underline;
  }
}

.linkWrapper {
  margin-top: 16px;
  display: flex;
  align-items: center;
}
