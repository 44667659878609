@use '../../../resources/my-medicoach/styles/scss.module' as *;

$overlayOpacity: 0.15;

.styleLessButton.styleLessButton {
  all: unset;
  position: relative;
  -webkit-tap-highlight-color: transparent;
  box-sizing: inherit;
  user-select: none;
  overflow: hidden;

  &:not([disabled]):not([aria-disabled='true']) {
    cursor: pointer;
  }

  &[disabled],
  &[aria-disabled='true'] {
    pointer-events: none;
  }

  // disable UA stylesheet
  &:disabled {
    color: inherit;
  }
}

.hoverOverlay {
  content: '';
  display: block;
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  background: currentColor;
  opacity: 0;
  pointer-events: none;
  animation: fadeIn 0.3s;

  &.isHovered {
    opacity: $overlayOpacity;
  }
}
