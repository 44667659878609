@use '../../../resources/my-medicoach/styles/scss.module' as *;

.fullpageLoading {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 0 40px;
  box-sizing: border-box;
}

.logo.logo {
  max-width: 300px;
}
