@use '../../resources/my-medicoach/styles/scss.module' as *;

$horizontalMargin: 16px;

.emptyContainer {
  --background-color: #{$blockColor};
  --text-color: #{$brand-white};

  &.light {
    --background-color: white;
    --text-color: rgb(54 54 54 / 0.8);
  }
}

.wysiwyg {
  word-wrap: break-word;

  h3,
  h2 {
    color: inherit;
    font-size: $fontSizeMainTitle;

    text-align: center;
    padding-bottom: 0;
    margin-bottom: 16px;
    font-family: $titleFont;
    font-weight: 500;
    position: relative;
  }

  p {
    color: inherit;
    font-size: $fontSizeDefault;
    letter-spacing: 0.08em;
  }

  > :first-child {
    margin-top: 0;
  }

  > :last-child {
    margin-bottom: 0;
  }
}

$arrowSpacing: 20px;
$arrowWidth: 12.58px;

.action {
  margin-top: 8px;

  > * {
    width: 100%;
  }
}
