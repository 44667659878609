.actionsGap {
  margin: -4px;
}

.rightAlignedActions.rightAlignedActions {
  margin-top: auto;
  margin-left: auto;
  width: fit-content;
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;

  & > * {
    margin: 4px;
    flex: 1 1 auto;
    max-width: calc(100% - 8px);
  }
}

.evenWidthActions.evenWidthActions {
  display: flex;
  flex-wrap: wrap;

  & > * {
    margin: 4px;
    flex: 1 1 calc(50% - 8px);
    min-width: min-content;
  }
}

.spaced.spaced {
  padding-top: 32px;
}

.floatingActions {
  flex-shrink: 0;
  pointer-events: none;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;

  /* autoprefixer doesn't add webkit-sticky :( */
  /* stylelint-disable-next-line */
  position: -webkit-sticky;
  position: sticky;
  bottom: calc(env(safe-area-inset-bottom, 0px) + 16px);
  margin-top: auto;
  margin-bottom: 16px;
  padding-top: 16px;
  //padding-right: 16px;
  //padding-left: 16px;

  &.centered {
    justify-content: center;
  }

  > * {
    pointer-events: all;
  }
}

.pageFiltersMargin {
  margin: 16px 0;
}

.pageFilters.pageFilters {
  margin: -4px;
  display: flex;
  justify-content: center;

  & > * {
    margin: 4px;
  }
}
