@use '../../resources/my-medicoach/styles/scss.module' as *;

.errorDisplay {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.swrErrorOverlay {
  position: absolute;
  z-index: 1;
  height: 100%;
  width: 100%;
  display: flex;
  background: rgb(0 0 0 / 0.12);
}

.crashReport {
  white-space: pre;
  overflow: auto;
  max-height: 300px;
  font-family: $monospaceFont;
  font-size: px-to-rem(12);
  background: $brand-black;
  color: $brand-white;
  border-radius: 4px;
  padding: 8px;
  margin: 8px 0 0;
}
