@use '../../resources/my-medicoach/styles/scss.module' as *;

.commonLayout {
  background: $brand-white;
  height: 100%;
}

.spanHeader {
  color: var(--header-text);
  background: var(--header-background);

  position: fixed;
  left: 0;
  top: 0;
  height: var(--top-bar-height, 64px);
  width: 100vw;
  z-index: 1;
}
