.top {
  --mask-top: transparent;
}

.right {
  --mask-right: transparent;
}

.bottom {
  --mask-bottom: transparent;
}

.left {
  --mask-left: transparent;
}

.overflowIndicator {
  mask-image: linear-gradient(
    to top,
    black 90%,
    var(--mask-top, black) 100%
  ),
    linear-gradient(
    to right,
    rgb(0 0 0 / 1) 90%,
    var(--mask-right, black) 100%
  ),
    linear-gradient(
    to bottom,
    rgb(0 0 0 / 1) 90%,
    var(--mask-bottom, black) 100%
  ),
    linear-gradient(
    to left,
    rgb(0 0 0 / 1) 90%,
    var(--mask-left, black) 100%
  );

  /* Autoprefixer doesn't generate this one properly */
  /* stylelint-disable-next-line */
  -webkit-mask-composite: source-in;
  mask-composite: intersect;
}
