@use 'sass:math';

$contentsPadding: 10px;
$topBarHeightMobile: 56px;
$topBarHeightDesktop: 64px;
$listTextStart: 72px;
$pageTextStart: 16px;

$blockPaddingVertical: 14px;

$gutter: 15px;

$container-padding: 16px;

$contentFont: Poppins, roboto, lato, 'Noto Sans', noto, 'Open Sans', sans-serif;
$titleFont: Rubik, roboto, sans-serif;
$monospaceFont: Monaco, Menlo, Consolas, 'Courier New', monospace;

$blockColor: rgb(21 140 192);

$materialShadow0: 0 1px 1px rgb(9 30 66 / 0.2), 0 0 1px 0 rgb(9 30 66 / 0.24);
$materialShadow1: 0 1px 3px rgb(0 0 0 / 0.12), 0 1px 2px rgb(0 0 0 / 0.24);
$materialShadow6: 0px 3px 5px -1px rgb(0 0 0 / 0.2),0px 6px 10px 0px rgb(0 0 0 / 0.14),0px 1px 18px 0px rgb(0 0 0 / 0.12);

$whiteHoverBackground: rgb(245 245 245);
$whiteActiveBackground: rgb(240 239 239);

$activeTransparencyOverlay: rgb(0 0 0 / 0.05);
$hoverTransparencyOverlay: rgb(0 0 0 / 0.03);
$hoverTransition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

$min-screen-size: 320px;
$desktop-min-width: 700px;

// ======= <THE ACTUAL PALETTE> =======

// -- Category Colors --

$catPhysiotherapyLight: rgb(117 193 231 / 1);
$catPhysiotherapyDark: rgb(26 124 173);

$catDieteticsLight: rgb(152 240 161 / 1);
$catDieteticsDark: rgb(37 126 67 / 1);

$catOccupationalTherapyLight: rgb(151 241 209 / 1);
$catOccupationalTherapyDark: rgb(68 126 106 / 1);

$catSpeechTherapyLight: rgb(248 146 171 / 1);
$catSpeechTherapyDark: rgb(200 65 99 / 1);

$catMedicineLight: rgb(203 165 221 / 1);
$catMedicineDark: rgb(124 89 169 / 1);

$catPsychologyLight: rgb(249 174 86 / 1);
$catPsychologyDark: rgb(187 85 12);

$catTobaccologyLight: rgb(215 238 124 / 1);
$catTobaccologyDark: rgb(78 121 53);

$catSportLight: rgb(148 179 238 / 1);
$catSportDark: rgb(71 101 158 / 1);

$catOtherLight: rgb(196 196 196 / 1);
$catOtherDark: rgb(115 115 115 / 1);

// -- Other Colors --

$brand-white: #fafafa;
$brand-black: #0a0a0a;

$primaryDark: $catPhysiotherapyDark; // rgb(20 132 182);
$primaryLight: $catPhysiotherapyLight;

/** @deprecated */
$secondaryDark: rgb(240 134 14);

/** @deprecated */
$secondaryLight: rgb(240 134 14);

$errorLight: rgb(244 111 111 / 1);
$errorDark: rgb(184 41 41 / 1);

$warningLight: rgb(249 153 86 / 1);
$warningDark: rgb(176 87 23 / 1);

$infoLight: rgb(120 240 233 / 1);
$infoDark: rgb(48 127 122 / 1);

$successLight: rgb(131 234 164 / 1);
$successDark: rgb(37 126 67 / 1);

// ----

$unreadNotificationColor: rgb(245 0 87);
$updateButtonColor: $successDark;

// ----

$grey: rgb(241 241 241);
$light_grey: rgb(248 248 248);
$dark_grey: rgb(205 205 205);

$white-background: $brand-white;

// ======= </THE ACTUAL PALETTE> =======

@mixin hover {
  body:not(:global(.no-hover)) & {
    &:hover:not(:active) {
      @content;
    }
  }
}

@function strip-unit($value) {
  @return math.div($value, $value * 0 + 1);
}

// https://css-tricks.com/snippets/css/fluid-typography/
@mixin fluid-font-size($min-vw, $max-vw, $min-font-size, $max-font-size) {
  $u1: unit($min-vw);
  $u2: unit($max-vw);
  $u3: unit($min-font-size);
  $u4: unit($max-font-size);

  @if $u1 == $u2 and $u1 == $u3 and $u3 == $u4 {
    & {
      font-size: $min-font-size;

      @media screen and (min-width: $min-vw) {
        font-size: calc(#{$min-font-size} + #{strip-unit($max-font-size - $min-font-size)} * ((100vw - #{$min-vw}) / #{strip-unit($max-vw - $min-vw)}));
      }

      @media screen and (min-width: $max-vw) {
        font-size: $max-font-size;
      }
    }
  }
}

// v2
@function fluid($min-vw, $max-vw, $min-size, $max-size) {
  $u1: unit($min-vw);
  $u2: unit($max-vw);
  $u3: unit($min-size);
  $u4: unit($max-size);

  @if $u1 == $u2 and $u1 == $u3 and $u1 == $u4 {
    @return max(#{$min-size}, min(#{$max-size}, calc(#{$min-size} + #{strip-unit($max-size - $min-size)} * ((100vw - #{$min-vw}) / #{strip-unit($max-vw - $min-vw)}))));
  }
}

@mixin default-focus-outline() {
  outline: 5px auto black;
  outline: 5px auto -webkit-focus-ring-color;
}

@function px-to-rem($value-as-px) {
  @return #{math.div($value-as-px, 16)}rem;
}

// https://type-scale.com/ - major second
$fontSizeMainTitle: #{px-to-rem(20.25)};
$fontSizeSubtitle: #{px-to-rem(18)};
$fontSizeDefault: #{px-to-rem(16)};
$fontSizeDetail: #{px-to-rem(14.22)};
$fontSizeTiny: #{px-to-rem(12.64)};
$defaultLineHeight: 1.65;
