@use '../../resources/my-medicoach/styles/scss.module' as *;

.snackbar.snackbar {
  bottom: calc(env(safe-area-inset-bottom) + 8px);
  max-width: 428px;

  > * {
    margin: 0;
    box-shadow: $materialShadow6;
    animation: appear 225ms cubic-bezier(0.4, 0, 0.2, 1);
  }

  &.hasType {
    :global(.MuiPaper-root) {
      background: var(--bg-color);
      border: 1px var(--color) solid;
      color: black;
    }
  }
}

@keyframes appear {
  from {
    opacity: 0;
    transform: scale(0);
  }

  to {
    opacity: 1;
    transform: none;
  }
}
