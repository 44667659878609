@use '../../resources/my-medicoach/styles/scss.module' as *;

.bodyColor,
.crash {
  background: $primaryDark;
}

.crash {
  min-height: 100vh;

  display: flex;

  padding: 16px;
  box-sizing: border-box;
  font-size: 1.2rem;

  > div {
    margin: auto;
    display: flex;
    flex-direction: column;
    max-width: min(420px, 100%);
  }

  h1 {
    margin-bottom: 16px;
    line-height: 1.25;
    font-size: px-to-rem(28.83);
    color: $brand-white;
  }

  p,
  .reportId {
    color: $brand-white;
  }

  p {
    margin: 0;
  }
}

.logo {
  margin: 1em 15px 0;
}

.reportIdGroup {
  margin: 16px 0 0;
}

.reportId {
  font-weight: bold;
  display: block;

  input {
    display: block;
    min-width: 100%;
    padding: 15px;
    box-sizing: border-box;
    margin-top: 0.5em;
    background: transparent;
    border: none;
    border-left: 3px $brand-white solid;
    color: $brand-white;
  }
}

.supportInfo {
  margin-top: 4px;
  font-size: $fontSizeDetail;
  font-style: italic;
}
