@use '../../resources/my-medicoach/styles/scss.module' as *;
@use 'sass:color';

:global {
  .section--color-light,
  .section--color-dark {
    --alert-error-icon: #{$errorDark};
    --alert-error-bg: #{$brand-white};

    --alert-warn-icon: #{$warningDark};
    --alert-warn-bg: #{$brand-white};

    --alert-info-icon: #{$infoDark};
    --alert-info-bg: #{$brand-white};

    --alert-success-icon: #{$successDark};
    --alert-success-bg: #{$brand-white};
  }

  .section--white {
    --alert-error-icon: #{$errorDark};
    --alert-error-bg: #{color.mix($errorLight, $brand-white, $weight: 10%)};

    --alert-warn-icon: #{$warningDark};
    --alert-warn-bg: #{color.mix($warningLight, $brand-white, $weight: 10%)};

    --alert-info-icon: #{$infoDark};
    --alert-info-bg: #{color.mix($infoLight, $brand-white, $weight: 10%)};

    --alert-success-icon: #{$successDark};
    --alert-success-bg: #{color.mix($successLight, $brand-white, $weight: 10%)};
  }
}

.alert {
  width: stretch;
  margin-bottom: 16px;
  padding: 6px 16px;

  background: var(--bg-color);
  border: 1px var(--color) solid;
  color: black;

  display: flex;
  align-items: center;
  flex-wrap: wrap;
  border-radius: 4px;
}

.centered {
  width: fit-content;
  margin: 16px auto;
}

.message {
  display: flex;
  width: 100%;
  padding: 8px 0;

  .shrink & {
    width: auto;
  }
}

.error {
  --color: var(--alert-error-icon);
  --bg-color: var(--alert-error-bg);
}

.warn {
  --color: var(--alert-warn-icon);
  --bg-color: var(--alert-warn-bg);
}

.info {
  --color: var(--alert-info-icon);
  --bg-color: var(--alert-info-bg);
}

.success {
  --color: var(--alert-success-icon);
  --bg-color: var(--alert-success-bg);
}

.icon.icon {
  fill: var(--color);
  margin-right: 8px;
  margin-top: 1px;
}

.contents {
  flex: 1;

  & > :first-child {
    margin-top: 0;
  }

  & > :last-child {
    margin-bottom: 0;
  }
}

.actions {
  margin-left: auto;
  padding-left: 12px;
  //margin-right: -10px;
  margin-bottom: 4px;
  margin-top: 4px;
}

.alertAction.alertAction {
  text-transform: uppercase;
  font-weight: bold;

  & + .alertAction {
    margin-left: 8px;
  }
}
