@use '../../../resources/my-medicoach/styles/zIndices.module' as *;

.mpl {
  display: flex;
  flex: 1;
  overflow: hidden;
  height: 100%;
  max-width: 1044.58px; // this width = PageWithDock - Dock
  width: 100%;
  margin: 0 auto;
}

.index {
  box-sizing: border-box;
  max-width: 376px;
  min-width: 260px;
  overflow: hidden;
  flex: 1 1 376px;
}

.main {
  flex: 1 1 668px;
  overflow: hidden;
  position: relative;
  min-width: 320px;
}

.index,
.main {
  position: relative;
}

.index::before,
.index::after,
.main::before,
.main::after {
  content: '';
  display: block;
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  width: 1px;

  background: rgb(0 0 0 / 0.12);
  z-index: #{$z-index-dock + 1};
}

.index::after,
.main::after {
  right: 0;
}

.index::before,
.main::before {
  left: 0;
}

.index + .main::before {
  display: none;
}
