.transitionGroupWrapper {
  overflow: hidden;
  position: relative;
  background: black;

  @supports (overflow: clip) {
    overflow: clip;
  }
}

.transitionGroupWrapper,
.transitionGroup {
  height: 100%;
}
